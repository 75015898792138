import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { AlertComponent } from '@myapp/shared/components';

@Injectable({ providedIn: 'root' })
export class AlertService {
	private modalRef: BsModalRef;

	constructor(
		private modalService: BsModalService
	) {
	}

	show(options: ModalOptions, actions?: any): Observable<boolean> {
		let result = new Subject<boolean>();

		this.modalRef = this.modalService.show(AlertComponent, {
			ignoreBackdropClick: true,
			class: options.class,
			animated: false,
			initialState: {
				model: options
			}
		});

		this.modalService.onHide.subscribe(response => {
			if (response == 'backdrop-click') {
				result.next(false);
			} else {
				result.next(this.modalRef.content.result || false);
			}

			result.complete();
		});

		return result.asObservable();
	}

	hide() {
		this.modalRef.hide();
	}
}
